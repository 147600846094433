import React from 'react';
import parse from 'html-react-parser';
import PropTypes from 'prop-types';
import { Row, Col, Container } from 'reactstrap';
import SeparatorTitle from '../separator-title/SeparatorTitle';
import { dateWithTimezoneFormat } from '../../../utils/formatters/date';
import UserFullName from '../../common/user-full-name/UserFullName';
import EconsultModalAttachment
  from '../../econsult/econsult-attachment-modal/EconsultModalAttachment';

const Comment = ({
  comment,
  isTitleLocked,
  eConsult,
  desktopVersion,
}) => (
  <Row>
    <Col data-auto={`comment-${comment.id}`}>
      <SeparatorTitle title="Comment" isLocked={isTitleLocked} />
      <p className="text-muted text-center font-size-smaller">
        {dateWithTimezoneFormat(comment.submitted_at)}
      </p>
      <UserFullName
        photoUrl={comment.photo}
        firstName={comment.first_name}
        lastName={comment.last_name}
        userTitle={comment.role !== 'Specialist' ? comment.role : comment.specialties}
        suffix={comment.suffix}
        desktopVersion={desktopVersion}
      />
      <p className="ml-3 mb-4 ql-editor h-auto" data-auto="comment-content">
        {parse(comment.comment)}
      </p>
      <Container className="comment-attachments-container p-0">
        {comment.attachments.map(attachment => (
          <EconsultModalAttachment
            key={`comment-${comment.id}-attachment-container-${attachment.id}`}
            attachment={attachment}
            eConsult={eConsult}
            desktopVersion={desktopVersion}
          />
        ))}
      </Container>
    </Col>
  </Row>
);

Comment.propTypes = {
  comment: PropTypes.shape({
    id: PropTypes.number,
    submitted_at: PropTypes.string.isRequired,
    photo: PropTypes.string,
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    role: PropTypes.string,
    comment: PropTypes.string.isRequired,
    attachments: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    })),
  }).isRequired,
  isTitleLocked: PropTypes.bool,
  eConsult: PropTypes.shape({
    patient_name: PropTypes.string.isRequired,
    patient_gender: PropTypes.string.isRequired,
    patient_age_in_days: PropTypes.number.isRequired,
  }).isRequired,
  desktopVersion: PropTypes.bool,
};

Comment.defaultProps = {
  isTitleLocked: false,
  desktopVersion: false,
};

export default Comment;
