import React from 'react';
import PropTypes from 'prop-types';
import { isUndefined, isEmpty, concat, sortBy } from 'lodash';
import moment from 'moment';
import SummaryResponse from '../summary-response/SummaryResponse';
import Comment from '../../common/comment/Comment';
import StatusUpdate from '../status-update/StatusUpdate';


/**
 * This Component will display all the eConsult events:
 *  1. Disposition History
 *  2. Current disposition
 *  3. Comments
 *  4. Rerouted status updated
 *  5. Completed statys update
 * Also the elements will be sorted by its creation date
 * @param eConsult eConsult will contain the events
 * @return {*}
 * @constructor
 */
const EConsultEvents = ({ eConsult, desktopVersion }) => {
  // =================================== UTILITIES ===================================

  /**
   * Some comments can be expressed as eConsult dispositions, for example: When the specialist
   * has set needs more info as disposition a comment is generated. The previous comment
   * can be treated as a specialist disposition
   * @param comment comment record
   * @return {{}} comment record expressed as disposition
  */
  const convertCommentToDisposition = comment => ({
    recommendation: comment.comment,
    appropriateness: 'pending_requester_feedback',
    disposition_date: comment.submitted_at,
    specialist_info: {
      first_name: comment.first_name,
      last_name: comment.last_name,
      suffix: comment.suffix,
      profile_photo: comment.photo,
      specialties: comment.specialties,
    },
  });

  // =================================== BUILDERS ===================================

  /**
   * Builds the eConsult's disposition history as summary response components, Commonly
   * used to express old specialist dispositions for example in case an old specialist
   * has set the econsult disposition as wrong specialty
   * @return {Array} elements with its date
   */
  const composeDispositionHistory = () => (
    eConsult.disposition_history ? eConsult.disposition_history.map(disposition => ({
      date: disposition.disposition_date,
      element: (
        <SummaryResponse
          key={disposition.appropriateness}
          disposition={disposition}
          desktopVersion={desktopVersion}
          disclaimerID={eConsult.disclaimer_id}
          visitUrgency={disposition.appropriateness === 'face_to_face' ? eConsult.visit_urgency_as_text : ''}
        />
      ),
    })) : []
  );

  /**
   * Builds the comments list as a set of comment and summary response components.
   * 1. Each disposition as needs more info will generate a comment that will be expressed as
   *  a specialist disposition
   * 2. Any comment generated by the provider or any follow up comment generated by the specialist
   *  will be generated as a comment component
   * @param commentsQueue data from comments api
   * @return {Array} elements with its date
   */
  const composeComments = () => (
    !(isUndefined(eConsult.comments) && isEmpty(eConsult.comments)) ?
      eConsult.comments.map((commentItem, index) => (
        {
          date: commentItem.submitted_at,
          element: commentItem.type === 'info' && commentItem.role === 'Specialist' ? (
            <SummaryResponse
              key={commentItem.id}
              disposition={convertCommentToDisposition(commentItem)}
              desktopVersion={desktopVersion}
              disclaimerID={eConsult.disclaimer_id}
            />
          ) : (
            <Comment
              key={commentItem.id}
              comment={commentItem}
              isTitleLocked={index !== eConsult.comments.length - 1}
              eConsult={eConsult}
              desktopVersion={desktopVersion}
            />
          ),
        }
      )) : []
  );

  /**
   * Builds an Status Update component indicating that the eConsult was rerouted to another
   * specialist. It will be displayed only if the eConsult has been rerouted
   * @return {Array} elements with its date
   */
  const composeRerouteUpdate = () => (
    eConsult.reroute_history ? eConsult.reroute_history.map(update => ({
      date: update.rerouted_at,
      element: (
        <StatusUpdate
          text="Request re-routed to"
          iconClass="aristamd-icon wrong_specialty"
          updatedAt={update.rerouted_at}
          textEmphasis={`${update.specialty}.`}
        />
      ),
    })) : []
  );

  /**
   * Builds an Status Update component indicating that the eConsult was completed by the provider.
   * It will be displayed only if the eConsult has been completed
   * @return {Array} elements with its date
   */
  const composeCompletedUpdate = () => (eConsult.completed_at ? [{
    date: eConsult.completed_at,
    element: (
      <StatusUpdate
        text="Request reviewed and finalized."
        iconClass="fa fa-check"
        updatedAt={eConsult.completed_at}
        textEmphasis="End of consultation."
      />
    ),
  }] : []);

  // =================================== COMPONENT STRUCTURE ===================================

  /**
   * Stores the econsult events as an array of elements with an associated date that
   * can be sorted
   */
  const eConsultEvents = concat(
    composeDispositionHistory(), // Builds disposition History
    composeRerouteUpdate(), // Builds rerouted at update
    composeCompletedUpdate(), // Builds completed at update
    composeComments(), // Builds comments list
  );

  return (
    <div className="econsult-timeline">
      {sortBy(eConsultEvents, piece => moment(piece.date.date ? piece.date.date : piece.date))
          .map(piece => (
            <div key={piece.date.date}>{piece.element}</div>
      ))}
    </div>
  );
};

EConsultEvents.propTypes = {
  eConsult: PropTypes.shape({
    appropriateness: PropTypes.string,
    attachments: PropTypes.array,
    disclaimer: PropTypes.bool,
    full_details: PropTypes.shape({
      date_of_birth: PropTypes.string.isRequired,
      patient_id: PropTypes.number.isRequired,
      mrn: PropTypes.string.isRequired,
      organization: PropTypes.string.isRequired,
      clinic: PropTypes.string.isRequired,
      masked_id: PropTypes.string.isRequired,
      notes: PropTypes.string,
      assignment_date: PropTypes.string.isRequired,
      disposition_due_date: PropTypes.string.isRequired,
    }),
    id: PropTypes.number.isRequired,
    issue_summary: PropTypes.string.isRequired,
    main_question: PropTypes.string.isRequired,
    patient_age_in_days: PropTypes.number.isRequired,
    patient_gender: PropTypes.string.isRequired,
    patient_name: PropTypes.string.isRequired,
    provider: PropTypes.string.isRequired,
    recommendation: PropTypes.string,
    recommended_specialty: PropTypes.string,
    specialist_form_version: PropTypes.string,
    status: PropTypes.string.isRequired,
    needs_disposition: PropTypes.bool.isRequired,
    reroute_history: PropTypes.arrayOf(PropTypes.shape({
      rerouted_at: PropTypes.shape({
        date: PropTypes.string,
      }),
      specialty: PropTypes.string,
    })),
    previous_specialty: PropTypes.string,
    visit_urgency_as_text: PropTypes.string,
  }).isRequired,
  desktopVersion: PropTypes.bool,
};

EConsultEvents.defaultProps = {
  desktopVersion: false,
};

export default EConsultEvents;
